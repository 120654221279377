import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import './cancel-success.css';
import Img from 'gatsby-image';
import Button from '../components/buttons/button';
import { ButtonColor, ButtonStyle } from '../models/button-style';

const CancelSuccess = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      cancelSuccess: file(relativePath: { eq: "cancel/cancel-success.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  /*let cancelOrStay = '';
  cancelOrStay = typeof cancelOrStay === undefined ? 'undefined' : location.state.cancelOrStay;*/

  let cancelOrStay;
  if (location.state) {
    cancelOrStay = location.state.cancelOrStay;
  } else if (typeof window !== 'undefined') {
    //cancelOrStay = 'undefined';
  } else {
    //broken idk
  }

  if (cancelOrStay == undefined || cancelOrStay == 'cancel') {
    return (
      <div>
        <div className="flex justify-center mt-40">
          <div className="mb-20 mt-10">
            <p className="header">You're always welcome back</p>
            <p className="subheader">
              You've{' '}
              <strong>
                <i>successfully cancelled</i>
              </strong>{' '}
              your Patronscan subscription. We sent you a confirmation email.
            </p>
            <Img className="cancel-image" fluid={data.cancelSuccess.childImageSharp.fluid} />
            <div className="bottomButtons">
              <a
                href="https://www.patronscan.com"
                className="block hover:text-grey-dark transition-color lg:text-base industry-picks"
              >
                <Button
                  buttonColor={ButtonColor.FormSubmission}
                  buttonStyle={ButtonStyle.CancelOffer}
                  text={'Done'}
                  buttonId={'CancelSubscriptionButton'}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (cancelOrStay == 'stay') {
    return (
      <div>
        <div className="flex justify-center">
          <div className="mb-20 mt-10">
            <p className="header">Thank You for Your Ongoing Business and Trust!</p>
            <div className="subtext">
              <p className="subheader-stay">
                We would like to extend a heartfelt thank you for choosing Patronscan as your
                trusted partner. Your loyalty and support mean the world to us, and we are excited
                to have you continue your journey with us.
              </p>
              <br></br>
              <p className="subheader-stay">
                Our customer success manager will be in touch with you shortly to discuss the
                details and help you with the upgrade process. We assure you that this upgrade will
                add even more value to your Patronscan experience and make it easier for you to
                manage your business.
              </p>
            </div>
            <Img className="cancel-image-stay" fluid={data.cancelSuccess.childImageSharp.fluid} />
            <div className="bottomButtonsSuccess">
              <a
                href="https://www.patronscan.com"
                className="block hover:text-grey-dark transition-color lg:text-base industry-picks"
              >
                {/* <Button
                  buttonColor={ButtonColor.FormSubmission}
                  buttonStyle={ButtonStyle.CancelOffer}
                  text={'Done'}
                  buttonId={'CancelSubscriptionButton'} */}
                {/* /> */}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CancelSuccess;
